import useAuthInit from '../../features/Auth/hooks/useAuthInit';
import useSiteInit from '../../features/Site/hooks/useSiteInit.js';


function useAppInitializer() {

  const authInit = useAuthInit();
  const siteInit = useSiteInit();
  return () => {
    authInit();
    siteInit();
  }
}

export default useAppInitializer
