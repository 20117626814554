import React from 'react'
import Slider from "react-slick"
import { Link } from 'react-router-dom'
import { IoIosArrowBack } from "react-icons/io";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styles from './PartnersCarousel.module.scss'


const SliderArrow = (props) => {
    let { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={style}
            onClick={onClick}
        >
            <IoIosArrowBack className="slick-arrow-icon" />
        </div>
    );
}


function PartnersCarousel(props) {
    const settings = {
        infinite: true,
        slidesToShow: 9,
        slidesToScroll: 1,
        adaptiveHeight: false,
        arrows: true,
        pauseOnHover: true,
        nextArrow: <SliderArrow dir="right" />,
        prevArrow: <SliderArrow dir="left" />,
        speed: 3000,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        swipeToSlide: true,
        centerMode: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
        ]
    };
    return (
        <>
            <div className={styles.partnersSecWrapper}>
                <div className={`${styles.partnersSec} container`}>
                    <Slider {...settings} className={styles.partnerSlider}>
                        {props.companies.map((company, index) => (
                            <div className={styles.slideItem} key={index}>
                                <div className={styles.top}>
                                    <Link to={company.link}>
                                        <img src={company.logo} alt={`${company.name || "partner"} logo`} />
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>

        </>
    );
}

export default PartnersCarousel