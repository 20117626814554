// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import { Provider } from 'react-redux';
// import 'bootstrap/dist/css/bootstrap.min.css';

// import store from './store';
// import { showLoader } from './features/GlobalLoader/globalLoaderSlice';
// import { render } from "react-dom";
// import App from './App';

// store.dispatch(showLoader({ id: 'app' }));

// render(
// 	<React.StrictMode>
// 		<Provider store={store}>
// 			<App />
// 		</Provider>
// 	</React.StrictMode>,
// 	document.getElementById("root")
// );
import React from 'react';
import { createRoot } from 'react-dom/client'; // Use createRoot from react-dom/client
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';

import store from './store';
import { showLoader } from './features/GlobalLoader/globalLoaderSlice';
import App from './App';

store.dispatch(showLoader({ id: 'app' }));

// Get the root DOM node
const rootElement = document.getElementById('root');

// Create the React root
const root = createRoot(rootElement);

// Render the app
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
