import React from 'react'
import Button from '../Button/Button'
import styles from './CompaniesTeaser.module.scss'

function CompaniesTeaser(props) {
  return (
    <div className={styles.teaserWrapper}>
      <div className={styles.teaser + ' container'}>
        <div className={styles.teaserHeadings}>
        <h1>{props.title}</h1>
        <Button to={props.button.button_link} className={styles.btnborder}>{props.button.button_text}</Button>
        </div>
        <div className={styles.teaserCnt}>
          <p>{props.subtitle}</p>
        </div>
      </div>
    </div>
  )
}

export default CompaniesTeaser