
import styles from './styles/home.module.scss'

import sideStrips from '../../assets/images/side-strips.png'
import useHomePage from './hooks/useHomePage'

function Home() {

  const { sections } = useHomePage()

  return (
    <div className={styles.home}>
      {sections}
    </div>

  )
}

export default Home